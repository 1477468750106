import React from 'react';
import classNames from 'classnames';
import ModuleTitle from '@/business-components/module-title';
import styles from './index.module.scss';
const tollBoxList = [
  {
    name: '品牌数集',
    describe: '中国新消费品牌数据库',
    link: '/consume-brand',
    image: '//assets-oss.cbndata.com/public/FpzZFXXAqWApdf_id2PAyWSOLthz.jpeg',
  },
  {
    name: 'YnjoyThis',
    describe: '品牌营销与视觉设计',
    link: 'https://www.ynjoythis.com/',
    image: '//assets-oss.cbndata.com/public/Foawq-5rRobC_eReDqEThC8s7Z8Q.png',
  },
];

export default function ToolBox() {
  return (
    <div className={styles.toolBox}>
      <ModuleTitle title="工具箱" className={styles.moduleTitle}></ModuleTitle>
      <div className={styles.toolBoxList}>
        {tollBoxList.map((item, index) => (
          <a
            className={classNames('flex-row-nowrap', 'flex-start-center', styles.toolBoxItem)}
            key={index}
            href={item.link}
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.toolBoxItemImg} src={item.image} alt="" />
            <div className={styles.toolBoxItemText}>
              <span className={styles.projectName}>{item.name}</span>
              <span>-{item.describe}</span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
