import React, { useState, useRef, useEffect } from 'react';
import { useInterval } from 'ahooks';
import classNames from 'classnames';
import { Carousel } from 'antd';
import { CircularProgressbar } from 'react-circular-progressbar';
import { imageFormat } from '@/utils/format';
import styles from './index.module.scss';
import 'react-circular-progressbar/dist/styles.css';

export default function Banner({ data = [], subData }) {
  const [percentage, setPercentage] = useState(data.map(() => 0));
  const [selectIndex, setSelectIndex] = useState(0);
  const carouselRef = useRef(null);
  useInterval(
    () => {
      setPercentage(percentage.map((item, index) => (index === selectIndex ? item + 5 : 0)));
    },
    200,
    { immediate: true }
  );
  useEffect(() => {
    const theFinishedIndex = percentage.findIndex(item => item > 100);
    if (theFinishedIndex >= 0) {
      setSelectIndex(theFinishedIndex === percentage.length - 1 ? 0 : theFinishedIndex + 1);
    }
  }, [percentage]);
  useEffect(() => {
    carouselRef.current.goTo(selectIndex);
  }, [selectIndex]);
  return (
    <div className={classNames('flex-row-nowrap', 'flex-between-start', styles.banner)}>
      <div className={styles.bannerLeft}>
        <div className={classNames('flex-row-nowrap', styles.bullet)}>
          {data.map((_, index) => (
            <div className={styles.circle} key={index} onClick={() => setSelectIndex(index)}>
              {selectIndex === index && <CircularProgressbar value={percentage[index]} />}
              <span className={styles.dot}></span>
            </div>
          ))}
        </div>
        <Carousel effect="fade" dots={false} ref={carouselRef}>
          {data.map((item, index) => (
            <div key={index}>
              {/* 为什么这边要注释不删呢，因为不知道业务会不会要求再改回原来的样式，所以先保留 */}
              {/* <div className={styles.titleContent}>
                <div className={styles.titleContentInner}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.subTitle}>{item.summary}</div>
                </div>
              </div> */}
              <div className={styles.bannerImgContent}>
                <a href={item.link} target="_blank" rel="noreferrer" className={styles.bannerImgContentLink}>
                  <div className={styles.title}>{item.title}</div>
                  <img className={styles.bannerImg} src={imageFormat(item.imgUrl, 500, 364)} />
                  <div className={styles.bannerOverlay}></div>
                </a>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={classNames('flex-column-nowrap', 'flex-between-start', styles.bannerRight)}>
        {subData?.map((item, index) => (
          <div className={styles.subBanner} key={index}>
            <a href={item.link} target="_blank" className={styles.subBannerLink} rel="noreferrer">
              <img src={imageFormat(item.imgUrl, 219, 136)} className={styles.subBannerLinkImg} />
            </a>
            <a>
              <div className={styles.textContent}>{item.title}</div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
