import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Tag from '@/components/tag';
import { imageFormat } from '@/utils/format';
import styles from './index.module.scss';

export default function NormalCard({ data, itemCardClick }) {
  return (
    <div className={classNames('flex-row-nowrap', 'flex-between-start', styles.normalCard)}>
      <a
        onClick={() => itemCardClick(data)}
        href={`/information/${data.id}`}
        target="_blank"
        className={styles.imageContent}
        rel="noreferrer"
      >
        <LazyLoadImage
          wrapperClassName={styles.imageWrapper}
          className={styles.image}
          src={imageFormat(data.image, 236, 132)}
          placeholderSrc="//images.dtcj.com/cbndata/icon/loading.gif"
        />
      </a>
      <div className={styles.rightContent}>
        <div className={classNames('flex-column-nowrap', 'flex-between-start', styles.rightSection)}>
          <div className={styles.header}>
            <a className={styles.title} href={`/information/${data.id}`} target="_blank" rel="noreferrer">
              {data.title}
            </a>
            <div className={styles.tagList}>
              {data.tags.map((item, index) => (
                <Tag key={index} text={item}></Tag>
              ))}
            </div>
          </div>
          <div className={classNames('flex-row-nowrap', 'flex-between-start', styles.bottom)}>
            <div className={styles.left}>
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-visibility"></use>
              </svg>
              <span className={styles.text}>{data.read_times}</span>
            </div>
            <div className={styles.right}>
              {dayjs(data.date).format('YYYY/MM/DD') === dayjs().format('YYYY/MM/DD')
                ? '今日'
                : dayjs(data.date).format('YYYY/MM/DD')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
