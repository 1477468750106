import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import styles from './index.module.scss';

interface ITag {
  homeTagId: string;
  locationType: string;
  oldTagId: string;
  sort: number;
  tagLevelId: string;
  tagLevelName: string;
}

interface ITagProps {
  tagList: Partial<ITag>[];
  selectId?: number | string;
  maxWidth?: string;
  onChange: (id: string) => any;
}

export default function TagList({ tagList, selectId, onChange }: ITagProps) {
  const [selectedId, setSelectedId] = useState(selectId || '');
  const handleSelectChange = useCallback(
    (value: Partial<ITag>) => {
      setSelectedId(value.oldTagId);
      onChange(value.oldTagId);
    },
    [onChange]
  );
  return (
    <div className={classNames('flex-row-nowrap', 'flex-start-center', styles.tagList)}>
      {tagList?.map((item, index) => (
        <a
          className={selectedId === item.oldTagId ? classNames(styles.tag, styles.selected) : styles.tag}
          key={index}
          onClick={() => handleSelectChange(item)}
        >
          {item.tagLevelName}
        </a>
      ))}
    </div>
  );
}
