import React from 'react';
import classNames from 'classnames';
import { useSetState, useRequest } from 'ahooks';
import ReactTooltip from 'react-tooltip';
import Layout from '@/layouts';
import Footer from '@/components/footer';
import RecommendActivity from '@/business-components/recommend-activity';
import WeeklyKol from '@/business-components/weekly-kol';
import TagsSubscription from '@/business-components/tags-subscription';
import SubscriptionModal from '@/business-components/subscription-modal';
import IndexContext from '@/context/IndexContext';
import type { SubscribeModalValue, IndexContextType } from '@/context/IndexContext';
import { getTagList, getTrendingTagsList } from '@/services/tag';
import Advertise from '@/components/advertise';
import MonthlyReportSubscription from '@/business-components/monthly-report-subscription';
import { splitArray } from '@/utils/helpers';
import SearchComponent from '@/components/search';
import Tags from '@/components/tags-antd';
import { getActivityList } from '@/services/activity';
import { getWeeklyKol } from '@/services/kol';
import ServerSideFetch from '@/utils/server-side-fetch';
import { getInformationList } from '@/services/information';
import { getAdvertiseList } from '@/services/advertise';
import { getReportList } from '@/services/report';
import { getTopicList } from '@/services/topic';
import Topic from './components/topic';
import Information from './components/information';
import Report from './components/report';
import Banner from './components/banner';
import ToolBox from './components/tool-box';
import styles from './index.module.scss';

const initialState: SubscribeModalValue = {
  subEmail: '',
  tagLevelId: '',
  tagName: '',
  type: 'subscribeReport',
  isOpen: false,
};
export default function Page({ activity, advertise, information, kols, report, searchTagList, topic }) {
  const { data: trendingTags, refresh } = useRequest(
    _ => {
      return getTrendingTagsList();
    },
    {
      initialData: [],
      formatResult(res) {
        return splitArray(res.data?.list || [], 10);
      },
    }
  );
  const [state, setState] = useSetState<SubscribeModalValue>(initialState);
  const value: IndexContextType = {
    ...state,
    activity,
    kols,
    trendingTags,
    refreshTrendingTagsList: refresh,
    setSubscribeModal: setState,
    resetSubscribeModal() {
      setState(initialState);
    },
  };
  return (
    <>
      <IndexContext.Provider value={value}>
        <Layout
          title="CBNData-第一财经商业数据中心"
          description="第一财经商业数据中心（CBNData）隶属于上海文化广播影视集团，是国内领先的消费研究机构及数字化增长服务商。CBNData消费站整合中国最大的财经媒体集团（第一财经）优势资源，通过消费数据研究报告、数据指数、新闻、活动、视频/直播等形式丰富的数字化消费行业应用场景，面向品牌企业全面输出消费数据、行业研究、信息聚合、营销传播及商业公关等产品及服务。"
          keyword="第一财经，大数据，cbn，新消费，消费，报告，研究报告，整合营销，行业研究，营销，新零售，传统零售，消费站，行业新闻，热点新闻"
          isShowNavBar
          FooterComponent={Footer}
        >
          <div className={classNames(styles.headerContent, styles.headerFixed)} ref={null}></div>
          <div className={styles.inner}>
            <div className={styles.homeSeachContent}>
              <>
                <div className={styles.inputContent}>
                  <SearchComponent height={40} placeholder="请输入 行业/人群/品牌/营销 关键词" />
                </div>
                <Tags tagList={searchTagList} />
              </>
            </div>
            <div className={styles.scrollContent}>
              <Banner data={advertise?.roundRobinBanner} subData={advertise?.singleBanner} />
              <Report dataList={report.dataList} tagList={report.tagList} />
              <div className={styles.horAdvertise}>
                <div className={styles.horAdvertiseContent}>
                  <Advertise
                    imageUrl={advertise?.horizontalAD ? advertise?.horizontalAD[0]?.imgUrl : ''}
                    link={advertise?.horizontalAD ? advertise?.horizontalAD[0]?.link : ''}
                    adIdentifier={advertise?.horizontalAD ? advertise?.horizontalAD[0]?.adIdentifier : -1}
                  />
                </div>
              </div>
              <div className={classNames('flex-row-nowrap', 'flex-between-start', styles.columnsLayout)}>
                <div className={styles.left}>
                  <Information dataList={[]} tagList={information.tagList}>
                    <Topic data={topic} />
                  </Information>
                </div>
                <div className={styles.right}>
                  <WeeklyKol />
                  <Advertise
                    width={345}
                    imageUrl={advertise?.rightSideAD1 ? advertise?.rightSideAD1[0]?.imgUrl : ''}
                    link={advertise?.rightSideAD1 ? advertise?.rightSideAD1[0]?.link : ''}
                    adIdentifier={advertise?.rightSideAD1 ? advertise?.rightSideAD1[0]?.adIdentifier : -1}
                    style={{ margin: '24px 0' }}
                  />
                  <TagsSubscription />
                  <MonthlyReportSubscription />
                  <ToolBox />
                  <RecommendActivity />
                  <Advertise
                    width={345}
                    imageUrl={advertise?.rightSideAD2 ? advertise?.rightSideAD2[0]?.imgUrl : ''}
                    link={advertise?.rightSideAD2 ? advertise?.rightSideAD2[0]?.link : ''}
                    adIdentifier={advertise?.rightSideAD2 ? advertise?.rightSideAD2[0]?.adIdentifier : -1}
                  />
                </div>
              </div>
            </div>
          </div>
        </Layout>
        <SubscriptionModal type={state.type} />
      </IndexContext.Provider>
      {/* <Footer /> */}
      <ReactTooltip id="tags" arrowColor="transparent" className={styles.tagsToolTip} />
    </>
  );
}
export async function getServerSideProps({ res }) {
  res.setHeader('Cache-Control', 'public, s-maxage=15, stale-while-revalidate=59');
  // Fetch data from external API
  // const [
  //   activityRes,
  //   advertiseRes,
  //   kolRes,
  //   searchTagListRes,
  //   topicRes,
  //   reportRes,
  //   reportTagListRes,
  //   informationTagListRes,
  //   informationRes,
  // ] = await Promise.all([
  //   getActivityList({ page: 1, pageSize: 4 }),
  //   getAdvertiseList({ type: 0 }),
  //   getWeeklyKol(),
  //   getTagList({ locationType: 'hotsearch' }),
  //   getTopicList(),
  //   getReportList(),
  //   getTagList({ locationType: 'report' }),
  //   getTagList({ locationType: 'news' }),
  //   getInformationList({ pageIndex: 1, pageSize: 10 }),
  // ]);
  const activityRes = await ServerSideFetch(getActivityList, { page: 1, pageSize: 4 });
  const advertiseRes = await ServerSideFetch(getAdvertiseList, { type: 0 });
  const kolRes = await ServerSideFetch(getWeeklyKol);
  const searchTagListRes = await ServerSideFetch(getTagList, { locationType: 'hotsearch' });
  const topicRes = await ServerSideFetch(getTopicList);
  const reportRes = await ServerSideFetch(getReportList);
  const reportTagListRes = await ServerSideFetch(getTagList, { locationType: 'report' });
  const informationTagListRes = await ServerSideFetch(getTagList, { locationType: 'news' });
  const informationRes = await ServerSideFetch(getInformationList, { pageIndex: 1, pageSize: 10 });
  try {
    const activity = activityRes.list || [];
    const advertise = advertiseRes.type2Portal || null;
    const kols = kolRes.list || [];
    const searchTagList = searchTagListRes.list || [];
    const topic = topicRes.list?.slice(0, 2) || [];
    const report = reportRes.list || [];
    const reportTagList =
      reportTagListRes.list && reportTagListRes.list.length > 0
        ? [{ oldTagId: '', tagLevelName: '全部' }, ...reportTagListRes.list]
        : [];
    const informationTagList =
      informationTagListRes.list && informationTagListRes.list.length > 0
        ? [{ oldTagId: '', tagLevelName: '全部' }, ...informationTagListRes.list]
        : [];
    const information = informationRes.list || [];
    // Pass data to the page via props

    return {
      props: {
        activity,
        advertise,
        kols,
        searchTagList,
        topic,
        report: {
          dataList: report || [],
          tagList: reportTagList?.slice(0, 7) || [],
        },
        information: {
          dataList: information || [],
          tagList: informationTagList?.slice(0, 7) || [],
        },
      },
    };
  } catch (error) {
    return {
      props: {
        activity: [],
        advertise: null,
        kols: [],
        searchTagList: [],
        topic: [],
        report: {
          dataList: [],
          tagList: [],
        },
        information: {
          dataList: [],
          tagList: [],
        },
      },
    };
  }
}
